import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function Signup() {

  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])

  const record = {name, email, password}

  async function Signup(e) {
    e.preventDefault()

    try {
        await axios.post('https://michaelapi.wd99p.com/api/v1/register', record).then(()=>{
            Swal.fire({
                title: "Registered Success!",
                text: "You can now login your newly created account.",
                icon: "success",
                showConfirmButton: true,
            });
        })
        setName('')
        setEmail('')
        setPassword('')
        navigate('/login')
    } catch (e) {
        setErrors(e.response.data.errors)
    }
}
    return (
        <div className='mb-5'>
            <div className="container mt-5 w-50 p-3 p-md-5 border border-warning">
                <h2 className='p-4 m-auto text-center'>Sign Up</h2>
                <form onSubmit={Signup}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" name="name" placeholder="Enter name" className={`form-control ${errors.name && 'is-invalid'}`} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input type="email" name="email" placeholder="Enter email" className={`form-control ${errors.email && 'is-invalid'}`} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" name="password" placeholder="Password" className={`form-control ${errors.password && 'is-invalid'}`} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="mb-3 d-grid">
                        <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                    </div>
                </form>
            </div>
        </div>
    );
}