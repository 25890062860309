import React from "react";
import './components/styles.css';
import Layout from "./common/Layout";
function App() {
  return (
  <div className="App">
    <Layout />
  </div>
  );  
}
export default App;