import React, { useEffect } from "react";
import { Routes, Route} from 'react-router-dom';
import About from "../components/About";
import Home from "../components/Home";
import Services from "../components/Services";
import Menu from "../components/Menu";
import ReservationForm from "../components/ReservationForm";
import OrderList from "../components/OrderList";
import Login from "../components/Login";
import Signup from "../components/Signup";
import MembershipCard from "../components/MambershipCard";
import { AuthProvider } from "../components/AuthContext";
const RouterApp = () => {
    return (
      <AuthProvider>
          <Routes>
              {/* Home */}
              <Route path="/" element={<Home />} />
              {/* About */}
              <Route path="/about" element={<About />} />
              {/* Menu */}
              <Route path="/menu" element={<Menu />}/>
              {/* Orders */}
              <Route path="/orders" element={<OrderList />}/>
              {/* Services */}
              <Route path="/services" element={<Services />} />
              {/* Login */}
              <Route path="/login" element={<Login />}/>
              {/* Register */}
              <Route path="/signup" element={<Signup />} />
              {/* Reservation */}
              <Route path="/reservation" element={<ReservationForm />}/>  
              {/* Login */}
              <Route path="/membership" element={<MembershipCard />} />
        </Routes>
      </AuthProvider>
    );
  };
  export default RouterApp;