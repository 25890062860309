import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../components/AuthContext';

const Login = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [formData, setFormData] = useState({
      email: '',
      password: ''
  });
  
  useEffect(()=>{
    console.log(isLoggedIn);
  }, [isLoggedIn])

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
        e.preventDefault();
          await axios.post('https://michaelapi.wd99p.com/api/v1/login', formData).then((res)=>{
            console.log('Login successful:', res.data);
            // Store token in localStorage
            localStorage.setItem('token', res.data.access_token);
            localStorage.setItem('userid', res.data.data.id);
            localStorage.setItem('username', res.data.data.name);
            setIsLoggedIn(true);
            // Show success message using SweetAlert
            Swal.fire({
              title: 'Login Successful!',
              text: 'You are now logged in.',
              icon: 'success',
              confirmButtonText: 'OK'
          }).then(()=>{
            window.location.href = '/'; // Redirect to home or dashboard after login
          })
          }).catch((error)=>{
            console.log(error)
            Swal.fire({
                title: 'Login Failed',
                text: 'Failed to log in. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
          });
    };
    return (
        <div className='mb-5'>
            <div className="container mt-5 w-50 p-3 p-md-5 border border-warning">
                <h2 className='p-4 m-auto text-center'>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input type="email" className="form-control" name="email" placeholder="Enter email" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Password" onChange={handleChange} required />
                    </div>
                    <div className="mb-3 d-grid">
                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Login;