import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('YOUR_PUBLISHABLE_STRIPE_KEY'); // Replace with your actual Stripe publishable key

const MembershipCard = () => {

  const handleBuyNow = async () => {
    const stripe = await stripePromise;
    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ priceId: 'PRICE_ID_OF_YOUR_PRODUCT' }), // Replace with your actual Stripe price ID
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <div>
      <h4 className="text-center m-5">Membership</h4>
      <div className="container m-auto">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card text-center border border-warning m-4">
              <img
                src={process.env.PUBLIC_URL + '/image/membership/membershipcard.png'}
                className="card-img-top m-auto p-4"
                alt="Membership Card"
                style={{ height: '500px', width: '450px' }}
              />
              <div className="card-body">
                <h5 className="card-title">Only P 499.00</h5>
                <p className="card-text">Get one now</p>
                <button onClick={handleBuyNow} className="btn btn-primary">
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipCard;