const About = () => {
    return (
    <div>
    <div id="about-section" className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6">
            <div className="row g-3">
              <div className="col-6 text-start">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={'https://michaelcapstone.wd99p.com/image/aboutimg/about-1.jpg'} />
              </div>
              <div className="col-6 text-start">
                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={'https://michaelcapstone.wd99p.com/image/aboutimg/about-2.jpg'} style={{ marginTop: '25%' }} />
              </div>
              <div className="col-6 text-end">
                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={'https://michaelcapstone.wd99p.com/image/aboutimg/about-3.jpg'} />
              </div>
              <div className="col-6 text-end">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={'https://michaelcapstone.wd99p.com/image/aboutimg/about-4.jpg'} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>
            <h1 className="mb-4">Welcome to <i className="fa fa-utensils text-primary me-2"></i>PHcuisine</h1>
            <p className="mb-4">Embark on a culinary journey through the vibrant flavors of the Philippines with PHcuisine. Our passion for authentic Filipino cuisine is matched only by our dedication to delivering exceptional dining experiences. From traditional classics to innovative creations, each dish tells a story of rich heritage and cultural diversity.</p>
            <p className="mb-4">At PHcuisine, we believe that great food brings people together. With a commitment to quality ingredients, meticulous preparation, and unparalleled hospitality, we strive to exceed your expectations with every bite. Whether you're craving the comforting warmth of adobo or the tantalizing sweetness of halo-halo, our menu is sure to delight your taste buds and leave you craving for more.</p>
            <div className="row g-4 mb-4">
              <div className="col-sm-6">
                <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                  <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15</h1>
                  <div className="ps-4">
                    <p className="mb-0">Years of</p>
                    <h6 className="text-uppercase mb-0">Experience</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                  <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50</h1>
                  <div className="ps-4">
                    <p className="mb-0">Popular</p>
                    <h6 className="text-uppercase mb-0">Master Chefs</h6>
                  </div>
                </div>
              </div>
            </div>
            <a className="btn btn-primary py-3 px-5 mt-2" href="">Read More</a>
          </div>
        </div>
      </div>
    </div>
</div>
      );
}
export default About;