import './styles.css';
import React, { useRef } from 'react';
import heroBg from "./heroBg.jpg";
function Home({}) {
    const homeRef = useRef(null);
  return (
    <div id="homepage" ref={homeRef} className="container-fluid p-5 bg-dark hero-header w-100" style={{ background: `linear-gradient(rgba(15, 23, 43, 0.5), rgba(15, 23, 43, .9)), url(${heroBg})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className="container my-4 py-4">
        <div className="row align-items-center g-5">
          <div className="col-lg-6 text-center text-lg-start">
            {/* Hero Header content */}
            <h1 className="display-3 text-white animated slideInLeft">Enjoy Our<br />Delicious Meal</h1>
            <p className="text-white animated slideInLeft mb-4 pb-2">We serve food that makes you happy!</p>
            <a href="/menu" className="btn btn-primary py-sm-3 px-sm-3 me-3 animated slideInLeft">Click here to <br/> Add an order before Booking A Table</a>
          </div>
          <div className="col-lg-6 text-center text-lg-end overflow-hidden">
            {/* Hero Header image */}
            <img className="img-fluid img-rotate" src="/image/heroImg.png" alt="Hero"/>
          </div>
        </div>
      </div>
    </div>
  );
}
  export default Home;