import Routes from "../router/RouterApp";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import ScrollToTop from "../components/ScrollToTop";
const Layout = () => {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Routes />
            <Carousel />
            <Footer />
        </>
    );
    }
export default Layout;