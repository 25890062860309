import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import "./styles.css";
const Navbar = () => {
const homeRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if(localStorage.getItem('token') && !isLoggedIn){
      setName(localStorage.getItem('username'))
      setIsLoggedIn(true)
  }
  console.log(isLoggedIn)
    setLoaded(true); 
  }, []);
  const handleLogout = () => {
    // Use Swal to show confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear token from local storage
        localStorage.removeItem('token');
        // Update auth state to logged out
        setIsLoggedIn(false);
        Swal.fire('Logged Out!', 'You have been logged out successfully.', 'success');
      }
    });
  };
  return (     
    <nav className="container-fluid navbar navbar-expand-sm navbar-dark bg-dark sticky-top w-100" >
      <Link to="/" className="navbar-brand p-0 m-0">
        <h1 className="text-primary m-0 pt-3"><i className="fa fa-utensils text-primary"></i> PHcuisine </h1>
      </Link>
      <button className="navbar-toggler" title="title" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span className="fa fa-bars"></span>   
      </button>
      <div className="collapse navbar-collapse" id="collapsibleNavbar" style={{ maxWidth: "100%", overflow: "hidden"}}>
        <div className="navbar-nav ms-auto py-0 pe-4">
          <Link to="/" className="selected1 nav-item nav-link text-white pb-0 m-0" id="home-nav" href=''>Home</Link>
          <Link to="/about" className="selected1 nav-item nav-link text-white pb-0 m-0" id="about-nav">About</Link>
          <Link to="/services" className="selected1 nav-item nav-link text-white pb-0 m-0" id="services-nav">Services</Link>
          <Link to="/menu" className="selected1 nav-item nav-link text-white pb-0 m-0" id="menu-nav" >Menu</Link>
          <a href="#contact-us" className="selected1 nav-item nav-link text-white pb-0 m-0" id="contact-nav" style={{ margin: '0px 25px'}}>Contact</a>
          {!isLoggedIn && (
            <>
          <div className='selected2 pt-4'>
            <Link to="/login" className="selected nav-item nav-link text-white p-1 btn btn-primary m-0" id="login-nav" style={{ height: '35px', width: '70px'}} >Login</Link>
          </div>
          <div className='selected2 pt-4'>
            <Link to="/signup" className="selected nav-item nav-link text-white p-1 btn btn-primary m-0" id="signup-nav" style={{ height: '35px', width: '100px'}} >Signup</Link>
            </div>
            </>
             )}
            {isLoggedIn && (
              <>
              <a href="javascript:void(0)" className="selected1 nav-item nav-link text-white pb-0 m-0" style={{ margin: '0px 25px'}}> Hi { name }!</a>
              <div className='selected2 pt-4'>
              <Link to="/logout" className="selected nav-item nav-link text-white p-1 btn btn-danger m-0" id="logout-nav" style={{ height: '35px', width: '100px'}} onClick={handleLogout} >Logout</Link>
              </div>
              </>
            )}
          <div className='pt-4'>
          <Link to="/orders" className="selected btn btn-success p-1 m-0" id="orders-nav" style={{ height: '35px', fontFamily: 'Nunito, sans-serif'}}><i className="fa fa-shopping-cart " ></i>
              <span>Orders</span>
          </Link>
          {/* <Link to="/membership" className="selected btn btn-info p-1 m-0" id="membership-nav" style={{ height: '35px', fontFamily: 'Nunito, sans-serif'}}><i className="fa fa-users " ></i>
              <span>Membership</span>
          </Link> */}
        </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;