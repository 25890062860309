import React from 'react';
function Services() {
  return (
    <div className="container-xxl pt-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">Our Services</h5>
          <h1 className="mb-5">Explore Our Services</h1>
        </div>
        <div className="row g-4">
          {services.map((service, index) => (
            <div key={index}  className="col-lg-3 col-sm-6 wow fadeInUp" style={{height: '600px'}}>
              <div className="service-item rounded pt-3" style={{}}>
                <div className="p-4" style={{ height: "480px" }}>
                  <i className={`fa fa-3x ${service.icon} text-primary mb-4`}></i>
                  <h5>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
const services = [
  {
    icon: "fa-user-tie",
    title: "Master Chefs",
    description: "Our Master Chefs are artisans, transforming ordinary ingredients into extraordinary culinary delights. With a keen eye for innovation and a flair for presentation, they push the boundaries of gastronomy, infusing traditional recipes with contemporary flair. Every dish is a masterpiece, meticulously crafted to delight the senses and ignite the imagination."
  },
  {
    icon: "fa-utensils",
    title: "Quality Food",
    description: "At PH Cuisine, quality isn't just a standard – it's our passion. We believe that exceptional food starts with exceptional ingredients, and that's why we go above and beyond to source the finest, freshest, and most flavorful ingredients for every dish we create."
  },
  {
    icon: "fa-cart-plus",
    title: "Online Order",
    description: "Whether you're planning a family dinner, a cozy night in, or a last-minute get-together with friends, our online ordering platform makes it easy to plan ahead. Simply place your order in advance, schedule delivery or pickup for a time that works for you, and relax knowing that a delicious meal is on its way."
  },
  {
    icon: "fa-headset",
    title: "Customer Service",
    description: "Hospitality is at the heart of everything we do. Whether you're a first-time visitor or a loyal patron, you'll always be greeted with a warm smile and treated like family at PHcuisine. Our friendly staff is here to make you feel at home, answering any questions you may have and ensuring that your dining experience is nothing short of extraordinary."
  }
];
export default Services;